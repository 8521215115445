import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import ReferenceComponent from '@components/references/reference.component'
import { references, glossaryReferences } from './references'

import SiteLink from '@components/SiteLink'
import FaqIndex from '../faq-list'
import GlossaryIndex from './glossary-terms-index'
import './faq-glossary.component.scss'
import { trackGa } from '@components/GoogleTracking'

const NarcolepsyFaqComponent = ({ glossary }) => {
  const [expandCount, setExpandCount] = React.useState()

  const getFaqGroup = (group) => {
    let result = []

    for (let faq of FaqIndex) {
      if (faq.group === group) {
        result.push(faq)
      }
    }

    return result
  }

  const getFaqQuestions = () => {
    return (
      <div>
        <div className="faq-group">
          <div className="faq-group-questions">
            {getFaqGroup('Narcolepsy').map((faq, index) => {
              return (
                <div key={index} className="faq-accordion-container">
                  <div className="faq-accordion-label" onClick={
                    () => {
                      faq.isExpanded = !faq.isExpanded
                      setExpandCount(!expandCount) // just to trigger a rerender
                      trackGa(faq.tracking)
                    }
                  }>
                    <h2 className="faq-question">
                      {faq.faq}
                    </h2>
                    <FontAwesomeIcon className="faq-expandable-icon" icon={faq.isExpanded ? faMinus : faPlus} />
                  </div>
                  <div className={`faq-accordion-content`}>
                    {faq.isExpanded ? faq.component : ""}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  const getGlossaryTerms = () => {
    return (
      <div className="faq-group">
        <div className="faq-group-questions glossary">
          {GlossaryIndex.map((term, index) => {
            return (
              <h2 key={index} className="faq-question">
                <SiteLink to={term.url} params={{ term: term.url }} tracking={term.tracking}>
                  {term.faq}
                  <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                </SiteLink>
              </h2>
            )
          })}
        </div>
      </div>
    )
  }

  let header = 'Frequently Asked Questions About Narcolepsy'
  let isFaq = true

  if (glossary) {
    isFaq = false
    header = 'Glossary of Terms'
  }

  return (
    <div className="narcolepsy-faq-wrapper">
      <div className="primary-container">
        <div className="header-area">
          <h1 className="main-page-header">{header}</h1>
        </div>
      </div>
      <div className="section-gradient" />
      <div className="primary-container">
        <div className="margin-top" />
        {isFaq && getFaqQuestions()}
        {!isFaq && getGlossaryTerms()}
      </div>
      <div className="primary-container">
        <ReferenceComponent referenceList={ isFaq ? references : glossaryReferences} />
      </div>
    </div>
  )
}

NarcolepsyFaqComponent.propTypes = {
  glossary: PropTypes.bool,
}

export default NarcolepsyFaqComponent
